import React, { useContext } from "react"
import { LanguageContext } from "../../context"
//import Icon from "../icon"
import image from "../../assets/images/podcast-microphone/foto-podcast@3x.png"
import PodcastItem from "./podcast-item"
import moment from "moment"
import Utils from "../../utils"

 const Podcast = (props) => {

   const { title, description, podcasts } = props
   const { t } = useContext(LanguageContext)
   return (
     <section className="podcast-list p-b-80 p-l-351 p-r-346 small-p-b-65">
       <div className="podcast-list-content">
         <div className="row">
           <div className="col-md-12 m-b-56">
             <h2 className="h2-medium-c bordeaux H2-Tablet-Medium-C--bordeaux"> {t(title)} </h2>
           </div>
           <div className="col-md-12">
              <h3 className="h5-medium-c black"> {t(description)} </h3>
           </div>
         </div>
         <div className="row m-b-65 gutter-0">
                <div className="col-md-2 m-r-20 width-200 image-wrapper">
                  <img className="image-mic" src={image} alt="podcast" />
                </div>
                 <PodcastItem
                    key={1}
                    title={podcasts?.edges[0]?.node?.title ? Utils.decodeHtmlEntities(podcasts?.edges[0]?.node?.title) : t("No hi ha podcasts aquesta setmana")}
                    subtitle={podcasts?.edges[0]?.node?.body?.summary ? Utils.decodeHtmlEntities(podcasts?.edges[0]?.node?.body?.summary) : '--'}
                    date={moment(podcasts?.edges[0]?.node?.field_date_podcast).format("DD-MM-YYYY")}
                    image=""
                    //url={podcasts?.edges[0]?.node?.url?.uri.replace("http:", "https:")}
                    url={podcasts?.edges[0]?.node?.url?.uri}
                    className="col-md-10 max-width-523 podcast-item"
                 />
         </div>
         <div className="row p-l-r-5 text-center">
           <div className="container">
             <a
              className="section-cta btn btn-outline btn-lg btn-outline-bordeaux"
              href="https://www.ivoox.com/listas-de-unida-terrassa-media_a4_32564550_1.html"
              target="_blank"
              >{t("Veure episodis")}</a>
           </div>
         </div>
       </div>
     </section>
   )
 }

 export default Podcast
