import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import TeamItem from "./team-item"
import Utils from "../../utils"
import { navigate, Link } from "gatsby"

 const TeamList = (props) => {

   const { title, teamList, lang, className } = props


   const { t } = useContext(LanguageContext)
   return (
     <section className={`team-list m-b-92 p-b-80 small-p-b-40 p-l-r-56 bg-green ${className}`}>
       <div className="team-list-content">
         <div className="row p-b-64 p-t-79">
           <div className="col-md-12">
             <h2 className="h2-medium-c white H2-Tablet-Medium-C--white"> {title} </h2>
           </div>
         </div>
         <div className="row m-b-65 gutter-0 d-flex justify-content-center small-m-b-0">
             {teamList?.map((article, index) => {
               return (
                 <TeamItem
                    key={article?.field_email}
                    title={article?.title}
                    email={article?.field_email}
                    role={article?.field_role}
                    type={article?.field_team_type}
                    date={article?.created}
                    image={article?.relationships?.field_image?.image_style_uri?.wide}
                    url={Utils.getPath('/lideratge-de-la-unida', lang)}
                    className={`col-md-3 team-list-item max-width-254 small-m-b-32 m-r-24 ${'team-list-item-' + index} ${teamList.length - 1 === index ? 'last-item' : ''}` }
                 />
               )
             })}
         </div>
         <div className="row p-l-r-5 text-center">
           <div className="container">
             <Link
             to={Utils.getPath('/lideratge-de-la-unida', lang)}
             className="section-cta btn btn-outline btn-lg btn-outline-white"
             >
              {t("Veure tot l'equip")}
             </Link>
           </div>
         </div>
       </div>
     </section>
   )
 }

 export default TeamList
