import React, { useContext, useState } from "react"
import { LanguageContext } from "../../context"
import { navigate } from "gatsby"
import Icon from "../icon"
import image from "../../assets/images/homepage/la-nostra-comunitat/img-1@3x.png"
import Button from "../button-generic"
import Img from "gatsby-image"
import AudioPlayer, {RHAP_UI} from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

 const PodcastItem = (props) => {

   const [animation, setAnimation] = useState(false);

   const {
     className,
     title,
     subtitle,
     date,
     image,
     url
   } = props


   const { t } = useContext(LanguageContext)
   return (
           <div className={className} key={1}>
             <div
              className="podcast-item-content"
              >

              <section className="m-l-30-">
               <div className="poddcast-item-desc h5-light-l-black" key={1}>
                <div className="row">
                  <div className="col-md-12">

                  <AudioPlayer
                    key={1}
                    src={url}
                    //src="http://www.poderato.com/file_send/mp3.mp3?mp3=12301l6718l179413.mp3"
                    onPlay={e => setAnimation(true)}
                    onPause={e => setAnimation(false)}
                    hasDefaultKeyBindings={false}
                    showJumpControls={false}
                    showDownloadProgress={false}
                    showFilledProgress={true}
                    layout="horizontal-reverse"
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    header={[
                      <div className="header">
                        <div className="rhap_date">{date}</div>
                        <div className="rhap_title_series">
                          <div className="rhap_title">{title}</div>
                          <div className="rhap_series">{subtitle}</div>
                        </div>
                      </div>
                    ]}
                    footer={[
                      <div className="rhap_icon">
                      <Icon
                          icon="icons-32-x-32-export-icon"
                          size={24}
                          className="icon bordeaux"
                          color="bordeaux"
                        />
                      </div>
                    ]
                    }

                    customIcons={{
                      play: <Icon
                                  icon="icons-32-x-32-export-play"
                                  size={64}
                                  className="icon bordeaux"
                                  color="bordeaux"
                                />,
                      pause: <Icon
                                  icon="icons-32-x-32-export-pause-video"
                                  size={64}
                                  className="icon black"
                                  color="bordeaux"
                                />
                    }}

                    customControlsSection={
                      [
                        RHAP_UI.ADDITIONAL_CONTROLS,
                        RHAP_UI.MAIN_CONTROLS,
                        RHAP_UI.VOLUME_CONTROLS,
                      ]
                    }

                    customProgressBarSection={
                      [
                        <div><div className="ocean">
                          <div className={`wave ${animation ? "wave-animate": ''}`}></div>
                          <div className={`wave ${animation ? "wave-animate": ''}`}></div>
                          <div className={`wave ${animation ? "wave-animate": ''}`}></div>
                        </div>
                        </div>,
                        RHAP_UI.PROGRESS_BAR,
                        RHAP_UI.CURRENT_TIME,
                        RHAP_UI.DURATION
                      ]
                    }

                                    // other props here
                  />
                </div>
                </div>
                </div>

              </section>

             </div>
           </div>
   )
 }

 export default PodcastItem
