import React, { useContext } from "react"
import { LanguageContext } from "../context"
import Icon from "./icon"
import Button from "./buttons/button-cta-top-content"
import modalBackgroundImage from "../assets/images/modal/modal-header-image-prayer.jpg"
import PropTypes from "prop-types"

 const HomeTopContent = (props) => {

   const { t } = useContext(LanguageContext)
   const { text, formId, buttonTitle, isButton, className} = props

   const wrapSpecialWords = (text) => {

      // Ensure text is a string
    if (typeof text !== 'string') {
      console.warn('wrapSpecialWords expected a string, received:', text);
      return text; // Return text as-is or provide a fallback
    }

     const specialWords = ['pregària', 'oración', 'prayer'];
     const regex = new RegExp(`(${specialWords.join('|')})`, 'gi');

     const parts = text.split(regex).map((part, index) =>
       specialWords.includes(part.toLowerCase())
         ? <span key={`special-${index}`} className="fill-backgrond">{part}</span>
         : part
     );

     return <span>{parts}</span>;
   };


   return (
      <section className={className}>
        <div className="container top-content">
          <div className="row">
            <div className="col-md-12">
              <div className="call-to-action-desc">
                {/*text*/}
                {wrapSpecialWords(t(text))}
              </div>
            </div>
            {buttonTitle && isButton &&
              <Button
                title={t(buttonTitle)}
                backgroundimage={modalBackgroundImage}
                formId={formId}
               />
            }
            {buttonTitle && !isButton &&
              <div
                className="text-center green-dark Normal-Bold-C---green-dark"
               >
                {buttonTitle}
              </div>
            }

          </div>
        </div>
      </section>
   )
 }

 HomeTopContent.propTypes = {
   buttonTitle: PropTypes.string,
   formId: PropTypes.string,
   isButton: PropTypes.bool,
   className: PropTypes.string
 }

 HomeTopContent.defaultProps = {
   buttonTitle: `Demanar Pregària`,
   formId: `prayer`,
   isButton: true,
   className: `row home-top-content m-b-152`
 }

 export default HomeTopContent
