import React, { useContext } from 'react'
import { Link, navigate } from 'gatsby'
import { LanguageContext } from '../../context'
import { homePagePaths, translate } from '../../translate'
import Icon from '../icon'


const EventsPlace = (props) => {

  const {
    place
  } = props

  const { t } = useContext(LanguageContext)

  return (
    <div className="event-place">
       <div className="event-place-label label">
       <Icon
            icon="icons-32-x-32-export-maps"
            className="icon m-r-12"
            size={24}
            color="green"
          />
          {t("On/ direcció")}
      </div>
       <div className="event-place-value"> {place} </div>
    </div>
  )
}

export default EventsPlace
