import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import Icon from "../icon"
//import Button from "../button-generic"
//import { GatsbyImage } from "gatsby-plugin-image"

 const TeamItem = (props) => {

   const {
     title,
     className,
     role,
     image,
     email,
     type
   } = props

   const { t } = useContext(LanguageContext)
   return (
           <div className={className}>
             <p className="team-ministry-name Small-bold-L---green-dark">{type !== "Pastoral" ? role.replace(/Directora|Director|/gi, "") : ""}</p>
             <article
              className="team-item-content"
              itemScope
              itemType="http://schema.org/Team"
              >
              <div className="image-top-left" style={{ maxWidth: `120px`, height:`120px!important`, width: `100%` }}>
                <img
                  src={image} alt={title}
                  className="team-image"
                />
              </div>
              <header className="m-l-30- p-t-24 p-l-68">
                <h2 className="team-item-title">
                    <span itemProp="headline"> {title} </span>
                </h2>
              </header>
              <section className="m-l-30-">
               <div className="team-item-desc h5-light-l-black p-l-68 m-b-17">
                <div className="row">
                  <div className="col-md-2 col-2 team-icon-wrapper p-l-6">
                    <Icon
                      icon="icons-32-x-32-export-person"
                      size={33}
                      className="icon"
                    />
                  </div>
                  <div className="col-md-10 col-10">
                    <div className="team-label small-bold-l-bordeaux">{t("Càrrec")}</div>
                    <div className="team-value normal-medium-l-black">{role}</div>
                  </div>
                </div>
                </div>
                <div className="team-item-contact p-l-28">
                    <div className="row">
                        <div className="col-md-2 col-2 team-icon-wrapper p-l-6-">
                        <Icon
                          icon="icons-32-x-32-export-mail"
                          size={24}
                          className="icon"
                        />
                        </div>
                        <div className="col-md-10 col-10">
                            <div className="team-label small-bold-l-bordeaux">{t("Contacte")}</div>
                            <div className="team-value normal-medium-l-black">{ email }</div>
                        </div>
                    </div>
                </div>
              </section>

             </article>
           </div>
   )
 }

 export default TeamItem
