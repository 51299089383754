import React, { useContext, useMemo } from "react"
import { LanguageContext } from "../../context"
import Button from "..//button-generic"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import {Link} from "gatsby"
import Icon from "../icon"
import "../../utils/group-by.js"


 const MinistriesList = (props) => {


   const data = useDataQuery();
   const ministries = data.ministries
   const { t } = useContext(LanguageContext)
   const { lang } = props
     // Filter ministries by language and then limit to 3
    const filteredAndLimitedMinistries = useMemo(() => {
      const filtered = data.ministries.edges.filter(edge => edge.node.langcode === lang);
      return filtered.slice(0, 3); // Limit to the first 3 ministries
    }, [data, lang]);

   //const groupedMinistries = useGroupedMinistries(ministries);
  
   const groupedMinistries = useGroupedMinistries({ edges: filteredAndLimitedMinistries });

   const breakpoints = useBreakpoint();

   return (
     <section className="row ministries-list m-b-80">
     <div className="container ministries-content">
      {groupedMinistries && groupedMinistries?.map((item, index) => {
        const first = index===0 && !item.group ? 'first' : ''
        const second = index===1 ? 'second m-t-330 p-l-r-56 m-b-66 medium-m-r-2' : ''
        const classes = `row ${first} ${second}`
        if(index === 0) {
          return(
            <div key={index} className={classes}>
              <div className="col-md-6 m-r-81 m-w-683 medium-m-r-25">
                <div className="ministry-image background-beig read-more right m-b-42">
                  <img
                    src={item?.items[0]?.node?.relationships?.field_image?.image_style_uri?._653_432}
                    alt={item?.items[0]?.node?.title}
                    className="m-r--24"
                  />
                  <Link
                   to={item?.items[0]?.node?.path?.alias}
                   className="r-more"
                  >
                   {t("Veure més")}
                   <Icon icon="icons-32-x-32-generic-plus" size={16} className="icon" />
                  </Link>
                </div>
              </div>
              <div className="col-md-6 m-w-507">
                <h2 className="title ministry-title m-b-8"> {item?.items[0]?.node?.title} </h2>
                <div className="ministry-desc">
                  <h5 dangerouslySetInnerHTML={{__html: item?.items[0]?.node?.body?.summary}}/>
                  </div>
              </div>
            </div>
          )
        }else{
          if(breakpoints.md){
            return (
                <>
                {item?.items?.map(({node}, ii) => {
                  const first1 = ii===0 ? 'col-md-6 second-first m-w-625 p-l-r-0- m-r-75- p-l-12 m-r-63' : ''
                  const second1 = ii===1 ? 'col-md-6 second-second m-t--304 m-w-625 p-l-r-0 ' : ''
                  const classes1 = `${first1} ${second1}`
                  return(
                    <div key={ii} className={classes}>
                      <div className="col-md-6 m-r-81 m-w-683 medium-m-r-25">
                        <div className="ministry-image background-beig read-more right m-b-42">
                          <img
                            src={node?.relationships?.field_image?.image_style_uri?._653_432}
                            alt={node?.title}
                            className="m-r--24"
                          />
                          <Link
                           to={node?.path?.alias}
                           className="r-more"
                          >
                           {t("Veure més")}
                           <Icon icon="icons-32-x-32-generic-plus" size={16} className="icon" />
                          </Link>

                        </div>
                      </div>
                      <div className="col-md-6 m-w-507">
                        <h2 className="title ministry-title m-b-8"> {node?.title} </h2>
                        <div className="ministry-desc">
                          <h5 dangerouslySetInnerHTML={{__html: node?.body?.summary}}/>
                        </div>
                      </div>
                    </div>
                  )
                })}
                </>
              )
          }
          else{
          return(
            <div key={index} className={classes}>
              {item?.items?.map(({node}, ii) => {
                  const first1 = ii===0 ? 'col-md-6 second-first m-w-625 p-l-r-0- m-r-75- p-l-12 m-r-63' : ''
                  const second1 = ii===1 ? 'col-md-6 second-second m-t--304 m-w-625 p-l-r-0 ' : ''
                  const classes1 = `${first1} ${second1}`
                  return(
                    <div key={ii} className={classes1}>
                      {/*ii == 0 &&*/
                        <>
                        <div className="ministry-image background-beig read-more m-b-42">
                          <img
                              src={node?.relationships?.field_image?.image_style_uri?._653_432}
                              alt={node?.title}
                              className="m-t--24 m-l-112"
                           />
                           <Link
                            to={node?.path?.alias}
                            className="r-more"
                           >
                            {t("Veure més")}
                            <Icon icon="icons-32-x-32-generic-plus" size={16} className="icon" />
                           </Link>

                        </div>
                        <div className="ministry-title-desc">
                          <h2 className="title ministry-title m-b-8">  {node?.title} </h2>
                          <div>
                           {/* <MobileOnlyComponent /> will only be displayed if max-width <= 320px  */}
                           {/*breakpoints.xs ? 'hola' : null*/}
                          </div>
                          <div className="ministry-desc">
                            <h5 dangerouslySetInnerHTML={{__html: node?.body?.summary}}/>
                          </div>
                        </div>
                        </>
                      }
                    </div>
                )
              })
             }
           </div>
          )
         }
        }
      })
      }
        <div className="row p-l-r-5 text-center">
        <div className="container">
            <Button
             title={t("Veure tots els ministeris")}
             className="miniestries-cta btn btn-outline btn-lg"
             onClick={() => navigate(`${lang !== 'ca' ? '/' + lang : ''}/serveix`)}
             />
        </div>
        </div>
     </div>
 </section>
   )
 }

 const useGroupedMinistries = (ministries) => {
    //console.log("MINISTRIES 170", ministries)
    // Ensure that ministries.edges is defined and has elements
    if (!ministries.edges || ministries.edges.length === 0) {
      return [];
    }

    // Grouping logic
    return ministries.edges.groupArrayBy((item, index) => index !== 0);
 }

 const useDataQuery = () => {
   return useStaticQuery(graphql`
      query MinistiresListQuery {
        ministries: allNodeMinistry(filter: {promote: {eq: true}}) {
        edges {
          node {
            path {
              alias
            }
            langcode
            sticky
            title
            promote
            body {
              summary
            }
            drupal_id
            relationships {
              field_image {
                image_style_uri {
                  large
                  medium
                  thumbnail
                  wide
                  _653_432
                }
              }
            }
            status
          }
        }
      }
      }
    `)
 }

 export default MinistriesList
