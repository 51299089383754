import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import Icon from "../icon"
import EventsDateCircle from "./events-date-circle"
import EventsDayHour from "./events-day-hour"
import EventsPlace from "./events-place"
import Button from "../button-generic"
import { navigate, Link } from "gatsby"
import Utils from "../../utils"


 const EventsItem = (props) => {

   const {
     title,
     day,
     month,
     description,
     datehour,
     place,
     path,
     className
   } = props

   const { t } = useContext(LanguageContext)
   const shortTitle = Utils.trimToN(title, 75, 3)
   return (
           <div className={className}>
             <EventsDateCircle day={day} month={month} />
             <div className="event-content">
               <div className="event-title m-b-5"> {shortTitle} </div>
               <div className="event-desc m-b-38"> {description} </div>
                <EventsDayHour datehour={datehour} />
                <EventsPlace place={place}  />
                <Link
                  className="r-more"
                  to={path}
                >
                {t("Veure més")}
                <Icon
                  icon="icons-32-x-32-generic-plus"
                  size={16}
                  className="icon"
                />
                </Link>
             </div>
           </div>
   )
 }

 export default EventsItem
